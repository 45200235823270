import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  ErrorOption,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Alert } from '@pulse-web-ui/alert';
import { Users } from '@pulse-web-ui/icons';

import { SectionContainer } from '@src/common-components/container';
import { BoldText, PreLineText } from '@src/common-components/headers';
import { Container, FormLabel, Skeleton } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents, insurancePersonDefaultData } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { InsurancePersonForm } from '@src/features/insurance-person-form';
import {
  useBlockNextStep,
  useRequest,
  useValidateProfileAuth,
} from '@src/hooks';
import { useNsDetailsInsured } from '@src/pages/ns-form/hooks';
import { updateOrderProfileData } from '@src/pages/ns-form/utils/update-order-profile-data';
import { personsFormSchema } from '@src/schemas';
import {
  AuthActionTypes,
  OrderActionTypes,
  SportNSActionTypes,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import { InsurancePersonsForm, InsurePerson } from '@src/types';
import {
  getDaysDuration,
  getFormattedDate,
  scrollToErrorField,
  setPaymentPageTexts,
} from '@src/utils';

import {
  useSportDraft,
  useSportNsOrderData,
  useSportNsOrderDataFormatting,
} from './hooks';
import { getInsuranceObjects } from './utils';

export const FormInsuranceSportNSData = () => {
  const [changedProfileData, setChangedProfileData] = useState<null | Record<
    string,
    string
  >>(null);
  const {
    state: {
      stateFormNSSport: { insurePersons, selectedStartDate, selectedEndDate },
      stateWizard: { wantNextStep },
      stateUser: { profile: stateProfile, profile },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const { t } = useTranslation();
  const [submitPersonsData, setSubmitPersonsData] = useState<
    undefined | InsurePerson[]
  >();
  const [indexMyPersonForm, setIndexMyPersonForm] = useState(-1);
  const { orderData } = useSportNsOrderData();
  const navigate = useNavigate();
  const { storeWantNextStep, storeSportNSInsurePersons, profileDataForForm } =
    useNsDetailsInsured();
  const { orderArray } = useSportNsOrderDataFormatting();
  const { profileError, profileIsLoading, profileRefetch } =
    useValidateProfileAuth();

  const {
    error: updateProfileError,
    isLoading: updateProfileIsLoading,
    res: updateProfileRes,
    refetch: updateProfileRefetch,
  } = useRequest(
    'updateProfileSportNSRequest',
    'post',
    '/v1/user/update-profile',
    {
      clientChange: {
        ...changedProfileData,
        email: stateProfile?.profile?.email,
        phone: stateProfile?.profile?.phone,
      },
    },
    [changedProfileData, profile],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (!profile?.profile.lastName) {
      profileRefetch();
    }
  }, [profile?.profile.lastName]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetIsPageLoading,
      payload: profileIsLoading || updateProfileIsLoading,
    });
  }, [profileIsLoading, updateProfileIsLoading]);

  const defaultValues = useMemo(
    () => ({
      persons: insurePersons.map((person) => ({
        id: uuidv4(),
        ...person,
      })),
    }),
    [insurePersons]
  );

  const methods = useForm<InsurancePersonsForm>({
    mode: 'all',
    resolver: yupResolver(personsFormSchema),
    defaultValues,
    shouldFocusError: false,
  });

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    watch,
    setError,
    formState: { errors, isSubmitted, isValid },
  } = methods;

  useSportDraft();
  const [_, setPersonsErrors] = useState(errors.persons);

  const { fields } = useFieldArray({
    control,
    name: 'persons',
    keyName: 'key',
  });

  useEffect(() => {
    if (isSubmitted) {
      setPersonsErrors(errors.persons);
    }
  }, [isSubmitted, errors.persons]);

  const handleSelectionMe = (personsIndex: number) => {
    const formData = getValues();
    const newData = formData.persons.map((item, idx) => {
      if (idx === personsIndex) {
        if (item.isMe) {
          return {
            ...item,
            ...profileDataForForm,
            isMe: true,
            isDisabledForm: stateProfile?.hasSubscriptions,
          };
        }
        return { ...insurancePersonDefaultData, id: item.id };
      }

      if (item.isMe) {
        return { ...insurancePersonDefaultData, id: item.id };
      }

      return item;
    });

    reset({
      persons: newData,
    });

    setPersonsErrors((prevState) => {
      const newErrors = prevState?.map((err, index) => {
        if (index === personsIndex) {
          return {};
        }
        return err;
      });

      setError('persons', newErrors as ErrorOption);
      return newErrors;
    });

    setIndexMyPersonForm((prevIndex) =>
      prevIndex === personsIndex ? -1 : personsIndex
    );
  };

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      const contractDuration = `P${getDaysDuration(
        selectedStartDate,
        selectedEndDate
      )}D`;

      dispatch({
        type: SportNSActionTypes.SetSelectedDuration,
        payload: contractDuration,
      });
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  useEffect(() => {
    if (updateProfileIsLoading) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: true,
      });
    }
  }, [updateProfileIsLoading]);

  useEffect(() => {
    const subscription = watch((value) =>
      storeSportNSInsurePersons(value.persons as InsurePerson[])
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (wantNextStep) {
      storeWantNextStep(false);

      handleSubmit(
        (data) => {
          let isEqual = true;
          const changedUserData = data.persons.filter((item) => item.isMe);
          if (changedUserData?.length) {
            for (const key in profileDataForForm) {
              if (
                profileDataForForm[key as keyof typeof profileDataForForm] !==
                changedUserData[0][key as keyof typeof profileDataForForm]
              ) {
                isEqual = false;
                break;
              }
            }
          }

          setPaymentPageTexts(
            t('COMMON:hints.ifSomethingHappens'),
            selectedStartDate
          );

          if (isEqual) {
            if (stateProfile?.profile) {
              dispatch({
                type: OrderActionTypes.SetOrder,
                payload: updateOrderProfileData(
                  orderArray,
                  stateProfile.profile
                ),
              });
            }

            orderData.productProperty.insuranceObjects = getInsuranceObjects(
              data.persons
            );

            dispatch({
              type: OrderActionTypes.SetOrderRequestData,
              payload: orderData,
            });
            navigate('/order-detail');
          } else if (changedUserData?.length) {
            const { firstName, lastName, middleName, birthDate } =
              changedUserData[0];

            const updateUserProfileData: Record<string, string> = {};
            if (!stateProfile?.lockedFields?.firstName && firstName) {
              updateUserProfileData.firstName = firstName;
            }
            if (!stateProfile?.lockedFields?.middleName) {
              updateUserProfileData.middleName = middleName || '';
            }
            if (!stateProfile?.lockedFields?.lastName && lastName) {
              updateUserProfileData.lastName = lastName;
            }
            if (!stateProfile?.lockedFields?.birthDate && birthDate) {
              updateUserProfileData.birthDate = getFormattedDate(
                new Date(birthDate)
              );
            }

            setChangedProfileData(updateUserProfileData);
            setSubmitPersonsData(data.persons);
          }
        },
        (errors) => {
          scrollToErrorField(errors);
        }
      )();
    }
  }, [wantNextStep]);

  useEffect(() => {
    if (
      !updateProfileIsLoading &&
      updateProfileRes &&
      submitPersonsData &&
      changedProfileData
    ) {
      const newData = [...submitPersonsData];

      const myIndex = newData.findIndex((item) => item.isMe);
      newData[myIndex].primaryRecordId =
        updateProfileRes.profile.primaryRecordId;

      orderData.productProperty.insuranceObjects = getInsuranceObjects(newData);

      dispatch({
        type: OrderActionTypes.SetOrderRequestData,
        payload: orderData,
      });

      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      navigate('/order-detail');
    }
  }, [
    updateProfileIsLoading,
    updateProfileRes,
    submitPersonsData,
    changedProfileData,
  ]);

  useEffect(() => {
    if (changedProfileData) {
      updateProfileRefetch();
    }
  }, [changedProfileData]);

  useEffect(() => {
    if (!updateProfileIsLoading && updateProfileRes) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      dispatch({
        type: UserActionTypes.SetProfile,
        payload: updateProfileRes,
      });

      dispatch({
        type: OrderActionTypes.SetOrder,
        payload: updateOrderProfileData(orderArray, updateProfileRes.profile),
      });
    }
  }, [updateProfileRes]);

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrderPageTitle,
      payload: t('SPORT_FORM:headers.pulseSport') || '',
    });

    setIndexMyPersonForm(insurePersons.findIndex(({ isMe }) => isMe));
  }, []);

  useEffect(() => {
    if (profile?.profile.lastName) {
      sendAnalyticEvent(analyticEvents.sportToStepData);
    }
  }, [profile?.profile.lastName]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetIsScrollToErrorElement,
      payload: !isValid,
    });

    return () => {
      dispatch({
        type: WizardActionTypes.SetIsScrollToErrorElement,
        payload: false,
      });
    };
  }, [isValid]);

  useBlockNextStep(profileIsLoading, profileError);

  if (
    updateProfileIsLoading ||
    profileIsLoading ||
    !profile?.profile.lastName
  ) {
    return <Skeleton />;
  }

  if (profileError) {
    const e = profileError?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: t('COMMON:errors.authorizationError'),
          subtitle: t('COMMON:errors.retryRegistration'),
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/authorize-fail');
    }

    return <GlobalErrorInfo retryHandler={profileRefetch} />;
  }

  if (updateProfileError) {
    const e = updateProfileError?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: t('COMMON:errors.authorizationError'),
          subtitle: t('COMMON:errors.retryRegistration'),
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/authorize-fail');
    }

    return <GlobalErrorInfo retryHandler={updateProfileRefetch} />;
  }
  return (
    <FormProvider {...methods}>
      <Container>
        <FormLabel marginBottom={16}>
          {t('COMMON:labels.insuredPersons')}
        </FormLabel>
        <SectionContainer>
          <Alert
            type="outlined"
            icon={<Users width={24} color="currentColor" />}
            description={
              <PreLineText>
                <Trans components={{ BoldText: <BoldText /> }}>
                  {t('SPORT_FORM:hints.insuredAge')}
                </Trans>
              </PreLineText>
            }
          />
        </SectionContainer>
        {fields.map((field, fieldIndex) => (
          <InsurancePersonForm
            key={field.id}
            personsIndex={fieldIndex}
            isLastPerson={fieldIndex + 1 === fields.length}
            selectedIndex={indexMyPersonForm}
            effectiveSince={selectedStartDate || new Date()}
            lockedFields={stateProfile?.lockedFields}
            handleSelectionMe={handleSelectionMe}
            pressEnterNextStep
            ageMin={field.isMe && !field.birthDate ? 18 : undefined}
          />
        ))}
      </Container>
    </FormProvider>
  );
};
