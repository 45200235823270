/* eslint-disable indent */
import { useEffect } from 'react';

export const scrollToIframe = () => {
  //For IOS
  setTimeout(() => {
    window.scroll({ top: -1, left: 0, behavior: 'smooth' });
  }, 10);
  setTimeout(() => {
    window.parent.postMessage({ action: 'scrollToIframe' }, '*');
  }, 100);
};

export const useScrollToIframeOnMount = () => {
  useEffect(() => {
    scrollToIframe();

    return () => {
      scrollToIframe();
    };
  }, []);
};
