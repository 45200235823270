export enum FlatSteps {
  ADDRESS = 1,
  RISKS = 2,
  ADDITIONAL_RISKS = 3,
  SUM = 4,
  PAYMENT_PERIODS = 5,
  AUTH = 6,
  ORDER_CONFIRMATION = 7,
  ORDER_PAYMENT = 8,
  PURCHASE_FLAT_TO_STEP_9_PAY = 9,
  PAYMENT_RESULT = 10,
}

export enum HouseSteps {
  ADDRESS = 1,
  MATERIALS = 2,
  RISKS = 3,
  ADDITIONAL_RISKS = 4,
  SUM = 5,
  PAYMENT_PERIODS = 6,
  AUTH = 7,
  ORDER_CONFIRMATION = 8,
  ORDER_PAYMENT = 9,
  PURCHASE_FLAT_TO_STEP_9_PAY = 10,
  PAYMENT_RESULT = 11,
}
