import { useContext, useEffect } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { StepWizardForm } from '@src/features';
import { useResetMiteSteps } from '@src/hooks';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';

import { useAnalyticsMite } from './hooks';
import { MiteInsured } from './mite-insured';
import { MiteRisks } from './mite-risks';

export const MiteForm: FC = () => {
  const {
    state: {
      stateWizard: { currentStep, isScrollToErrorElement },
      stateMite: { choosedPolicy },
    },
    dispatch,
  } = useContext(Store);

  const navigate = useNavigate();

  useEffect(() => {
    if (!choosedPolicy) {
      navigate('/mite');
    }
  }, []);

  useAnalyticsMite();

  const goBack = () => {
    if (currentStep === 1) {
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: undefined,
      });

      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetAuthenticationRefetchUid,
        payload: uuidv4(),
      });

      navigate('/mite');
    } else {
      navigate(-1);
    }
  };

  return (
    <StepWizardForm
      maxStep={2}
      useResetSteps={useResetMiteSteps} // TODO: реализовать логику для хука useResetMiteSteps
      goBack={goBack}
      scrollToIframeOnChange={!isScrollToErrorElement}
    >
      {currentStep === 1 && <MiteRisks />}
      {currentStep === 2 && <MiteInsured />}
    </StepWizardForm>
  );
};
