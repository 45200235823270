/* eslint-disable indent */
import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

import type { SumPerPeriodWrapperProps } from './ifl-sum-per-period.types';

export const SumPerPeriodWrapper = styled.div<SumPerPeriodWrapperProps>`
  ${mixins.flex({ horizontal: false, justifyContent: 'flex-start' })}
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 16px 24px 12px;
  border-radius: ${({ theme }) => theme.common.radius12};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  background: ${({ theme }) => theme.colors.surface.background};
  min-height: 81px;
  box-sizing: border-box;
  width: 100%;
  justify-content: flex-start;

  ${media.desktop} {
    min-height: 114px;
    height: 100%;
  }
`;

export const SumPerPeriodValue = styled.div`
  ${typography.medium24}
  white-space: nowrap;
  margin-bottom: 2px;
`;

export const SumPerPeriodTitle = styled.div`
  ${typography.regular16}
  margin-bottom: 2px;
`;

export const StyledSpinnerWrapper = styled.div`
  margin-top: 6px;
`;

export const SumPerPeriodErrorMessage = styled.div`
  ${typography.regular14}
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.text.error};
  padding-top: 16px;

  ${media.desktop} {
    padding-top: 13px;
  }
`;

export const SumPerPeriodDescWrapper = styled.div`
  ${typography.regular16}
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const SumPerPeriodPromoWrapper = styled.div`
  margin-bottom: 2px;
`;

export const SumPerPeriodBaseValue = styled.span`
  ${typography.regular16}
  line-height: 24px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-left: 8px;
`;

export const SumPerPeriodPromoValue = styled.span`
  ${typography.medium24}
`;

export const SumPerPeriodPromoDesc = styled.span`
  ${typography.medium24}
  color: ${({ theme }) => theme.colors.text.primary};
`;
