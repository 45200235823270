/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import isEqual from 'lodash.isequal';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';

import { PageBackButton, StyledButton } from '@src/common-components/button';
import {
  ArrowLeftIcon2,
  Container,
  OrderSubTitle,
  OrderTitle,
  Skeleton,
} from '@src/components';
import { FormSubTitleWithSubText } from '@src/components/headers';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  FlatSteps,
  HouseSteps,
  IFrameMessage,
  POST_MESSAGE_TYPE,
  Product,
  UseQueryStatus,
  analyticEvents,
  iflFlatRoute,
  iflHouseRoute,
  iflRoute,
  miteRoute,
  miteStepsRoute,
  nsRoute,
  petsRoute,
  sportNsRoute,
  televetPlusRoute,
} from '@src/constants';
import {
  FormIFLGetPricesFull,
  SubmitEndpoints,
} from '@src/constants/request-data';
import { GlobalErrorInfo } from '@src/features';
import {
  useHandlePressKey,
  useIsDesktop,
  useRequest,
  useValidateProfileAuth,
  useWebanalyticParams,
} from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import { useFlatOrderArray } from '@src/pages/ifl-flat-form/hooks';
import { useHouseOrderArray } from '@src/pages/ifl-house-form/hooks';
import { usePetsTelevetPlusOrderArray } from '@src/pages/pets-televet-plus/hooks';
import { formOrderDetailSchema } from '@src/schemas';
import { OrderActionTypes, Store, UserActionTypes } from '@src/store';
import {
  KeyCode,
  OrderItemAccordionType,
  OrderItemType,
  OrderItemValueType,
  OrderItemsBlockType,
  OrderRequestData,
  SubmitData,
} from '@src/types';
import {
  addTestAttribute,
  handleAnalyticsEvents,
  scrollToIframe,
  sendFlatProgress,
  sendHouseProgress,
  useScrollToIframeOnMount,
} from '@src/utils';

import { changeMiteUserPrimaryRecordId } from '../mite/utils';
import { changeNsUserPrimaryRecordId } from '../ns-form/utils';
import { usePetsOrderArray } from '../pets-form/hooks/use-pets-order-array';
import { changeSportUserPrimaryRecordId } from '../sport-form/utils';
import {
  EmailWrapper,
  OrderContentWrapper,
  OrderPageTitleWrapper,
} from './components/components.styles';
import { OrderAccordionItem } from './components/order-accordion-item';
import { OrderBlockItem } from './components/order-block-item';
import { OrderItem } from './components/order-item';

const getUrl = (authorizeRefRoute: string | undefined) => {
  switch (authorizeRefRoute) {
    case sportNsRoute:
      return SubmitEndpoints.SPORT;

    case miteRoute:
      return SubmitEndpoints.MITE;

    case televetPlusRoute:
      return '/v3/subscription/submit/televetplus';

    default:
      return SubmitEndpoints.OTHER;
  }
};

export const OrderDetail: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateUser: { profile, preset, selectedProduct, agentLogin },
      stateOrder: {
        order,
        orderSubmitData,
        orderPageTitle,
        orderRequestData,
        cachedOrderRequestData,
      },
      stateAuth: { authTokens, authorizeRefRoute },
      stateWizard: { currentStep },
    },
    dispatch,
  } = useContext(Store);
  const isDesktop = useIsDesktop();
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);
  const [shouldUpdateProfile, setShouldUpdateProfile] =
    useState<boolean>(false);
  const [shouldNavigateCheckout, setShouldNavigateCheckout] =
    useState<boolean>(false);
  const [updatedEmail, setUpdatedEmail] = useState<string | undefined | null>();
  const currentSelectedProduct = localStorage.getItem('selectedProduct');

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(formOrderDetailSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      email: profile?.profile.email,
    },
  });

  const { profileRefetch, profileIsLoading, profileError } =
    useValidateProfileAuth();

  const setPetsOrderArray = usePetsOrderArray();
  const setFlatOrderArray = useFlatOrderArray();
  const setHouseOrderArray = useHouseOrderArray();
  const setPetsTelevetPlusOrderArray = usePetsTelevetPlusOrderArray();

  const isTelevetPlusRoute = selectedProduct === Product.PETS_TELEVET_PLUS;
  const queryClient = useQueryClient();

  const postSubmitMessageToDigitalAgent = useCallback(() => {
    window.top?.postMessage(
      JSON.stringify({
        message: IFrameMessage.SUBSCRIPTION_CREATED,
        type: POST_MESSAGE_TYPE,
      }),
      window.envUrls.AGENT_URL
    );
  }, []);

  useEffect(() => {
    if (!profile?.profile.lastName) {
      profileRefetch();
    }
  }, []);

  useScrollToIframeOnMount();

  useEffect(() => {
    if (!!profile) {
      setValue('email', profile?.profile.email);

      switch (selectedProduct) {
        case Product.HOUSE:
          setHouseOrderArray();
          break;
        case Product.APARTMENT:
          setFlatOrderArray();
          break;
        case Product.PETS:
          setPetsOrderArray();
          break;
        case Product.PETS_TELEVET_PLUS:
          setPetsTelevetPlusOrderArray();
          break;
        default:
          break;
      }
    }
  }, [profile]);

  const orderSubTitle = useMemo(() => {
    switch (authorizeRefRoute) {
      case petsRoute:
        return t('ORDER:labels.checkThatEverythingCorrect');
      case televetPlusRoute:
        return t('PETS_TELEVET_PLUS:labels.checkEverythingCorrect');
      default:
        return t('ORDER:labels.checkIfEverythingCorrect');
    }
  }, [authorizeRefRoute]);

  const orderAdditionalSubTitle = useMemo(() => {
    switch (authorizeRefRoute) {
      case iflRoute:
      case iflHouseRoute:
      case iflFlatRoute:
      case nsRoute:
      case miteRoute:
        return t('ORDER:labels.youCanChangeTermsOfSubscription');
      case petsRoute:
        return '';
      default:
        return t('ORDER:labels.youCanChangeTermsOfSubscription');
    }
  }, [authorizeRefRoute]);

  const getPageTitle = useCallback(
    (name) => {
      switch (authorizeRefRoute) {
        case petsRoute:
          return t('ORDER:headers.yourSubscriptionIsReady', { name });
        case sportNsRoute:
          return t('ORDER:headers.policyIsReady', { name });
        case miteRoute:
          return t('MITE_FORM:headers.policyIsReady', { name });
        case televetPlusRoute:
          return t('PETS_TELEVET_PLUS:headers.policyIsReady', { name });
        default:
          return t('ORDER:headers.subscriptionIsReady', { name });
      }
    },
    [authorizeRefRoute]
  );

  const pageSubTitle = useMemo(() => {
    switch (authorizeRefRoute) {
      case sportNsRoute:
      case miteRoute:
      case televetPlusRoute:
        return null;

      case iflFlatRoute:
      case iflHouseRoute:
        return <> {orderAdditionalSubTitle}</>;

      default:
        return (
          <>
            <br />
            {orderAdditionalSubTitle}
          </>
        );
    }
  }, [authorizeRefRoute]);

  const emailLabel = isTelevetPlusRoute
    ? t('PETS_TELEVET_PLUS:labels.willSendDocuments')
    : t('COMMON:labels.sendPolicyToMail');

  useEffect(() => {
    handleAnalyticsEvents.handleOrderAnalyticsEvents({
      authorizeRefRoute,
      preset,
      profile: profile?.profile,
    });
  }, [profile?.profile.lastName]);

  const navigate = useNavigate();

  const { isLoading, error, res, refetch, status } = useRequest(
    'formIFLSubscriptionSubmit',
    'post',
    getUrl(authorizeRefRoute),
    {
      ...orderRequestData,
    },
    [orderSubmitData, orderRequestData, updatedEmail],
    true,
    authTokens?.authorization?.accessToken
  );

  const {
    isLoading: updateProfileIsLoading,
    error: updateProfileError,
    res: updateProfileRes,
    refetch: updateProfileRefetch,
  } = useRequest(
    'updateProfileRequest',
    'post',
    '/v1/user/update-profile',
    {
      clientChange: {
        email: updatedEmail,
      },
    },
    [updatedEmail, authTokens?.authorization?.accessToken],
    true,
    authTokens?.authorization?.accessToken
  );

  const handleClickBack = () => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPreviousRealty, {
          screen: currentStep + 1,
        });
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPreviousAccident, {
          screen: currentStep + 1,
        });
        break;
      // TODO: добавить аналитику питомцев
    }

    queryClient.resetQueries(FormIFLGetPricesFull);

    navigate(
      selectedProduct === Product.MITE ? miteStepsRoute : `/${selectedProduct}`
    );
  };

  const onSubmitPageHandler = handleSubmit((data) => {
    localStorage.setItem('email', data?.email || profile?.profile.email || '');

    if (!profile?.profile.email || profile?.profile.email !== data.email) {
      setUpdatedEmail(data.email);
      setShouldUpdateProfile(true);

      updateProfileRefetch();
    } else {
      setShouldSubmit(true);
      setShouldNavigateCheckout(true);
    }

    scrollToIframe();

    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPurchaseRealty);
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPurchaseAccident);
        break;
      // TODO: добавить аналитику питомцев
    }
  });

  const handleKeyPressEnter = () => onSubmitPageHandler();
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  useEffect(() => {
    if (!updateProfileIsLoading && updateProfileRes) {
      setShouldUpdateProfile(false);

      dispatch({
        type: UserActionTypes.SetProfile,
        payload: updateProfileRes,
      });

      if (
        authorizeRefRoute === sportNsRoute ||
        authorizeRefRoute === nsRoute ||
        authorizeRefRoute === miteRoute
      ) {
        const newOrderRequestData =
          authorizeRefRoute === sportNsRoute
            ? changeSportUserPrimaryRecordId(
                orderRequestData as SubmitData,
                updateProfileRes.profile.primaryRecordId
              )
            : authorizeRefRoute === miteRoute
            ? changeMiteUserPrimaryRecordId(
                orderRequestData as SubmitData,
                updateProfileRes.profile.primaryRecordId
              )
            : changeNsUserPrimaryRecordId(
                orderRequestData as OrderRequestData,
                updateProfileRes.profile.primaryRecordId
              );

        dispatch({
          type: OrderActionTypes.SetOrderRequestData,
          payload: newOrderRequestData,
        });
      } else {
        setShouldSubmit(true);
        setShouldNavigateCheckout(true);
      }
    }
  }, [updateProfileIsLoading, updateProfileRes]);

  useEffect(() => {
    if (!isLoading && res) {
      scrollToIframe();
      setShouldSubmit(false);
      dispatch({
        type: OrderActionTypes.SetCachedOrderRequestData,
        payload: orderRequestData,
      });

      dispatch({
        type: OrderActionTypes.SetOrderSubmitData,
        payload: res,
      });
    }
  }, [res]);

  useEffect(() => {
    if (error && !res) {
      const e = error?.response?.status;

      if (e === 400 && error?.response?.data?.code === 'USER_DATA_ERROR') {
        if (!!agentLogin?.length) {
          window.top?.postMessage(
            JSON.stringify({
              message: IFrameMessage.SUBSCRIPTION_ERROR,
              type: POST_MESSAGE_TYPE,
            }),
            window.envUrls.AGENT_URL
          );
        }

        dispatch({
          type: UserActionTypes.SetIsScrinning,
          payload: true,
        });

        scrollToIframe();
        navigate('/score-error');
      }
    }
  }, [error, res]);

  useEffect(() => {
    if (updateProfileError && !updateProfileRes) {
      if (updateProfileError?.response?.data?.code === 'NOT_UNIQUE_EMAIL') {
        setError('email', {
          type: 'string',
          message: 'COMMON:errors.emailAlreadyExist',
        });
      }
    }
  }, [updateProfileError, updateProfileRes]);

  useEffect(() => {
    if (!order && !(selectedProduct === Product.PETS || isTelevetPlusRoute)) {
      navigate(authorizeRefRoute ? authorizeRefRoute : '/');
    }
  }, [order]);

  useWebanalyticParams();

  useEffect(() => {
    if (updatedEmail) {
      setShouldSubmit(true);
      setShouldNavigateCheckout(true);
    }
  }, [orderRequestData]);

  useEffect(() => {
    if (
      (shouldSubmit &&
        !isLoading &&
        !isEqual(orderRequestData, cachedOrderRequestData)) ||
      shouldUpdateProfile
    ) {
      setShouldSubmit(false);
      setShouldUpdateProfile(false);

      if (shouldUpdateProfile) {
        if (
          !updateProfileIsLoading &&
          updateProfileRes &&
          !updateProfileError
        ) {
          refetch();
        }
      } else if (
        status === UseQueryStatus.IDLE ||
        status === UseQueryStatus.ERROR
      ) {
        refetch();
      }
    } else if (shouldNavigateCheckout && !isLoading && !error) {
      setShouldNavigateCheckout(false);

      if (agentLogin) {
        postSubmitMessageToDigitalAgent();
      } else {
        navigate('/order-checkout');
      }
    }
  }, [
    shouldUpdateProfile,
    updateProfileIsLoading,
    updateProfileRes,
    updateProfileError,
    shouldSubmit,
    shouldNavigateCheckout,
    isLoading,
  ]);

  useEffect(() => {
    switch (currentSelectedProduct) {
      case Product.APARTMENT:
        sendFlatProgress(FlatSteps.ORDER_CONFIRMATION);
        break;
      case Product.HOUSE:
        sendHouseProgress(HouseSteps.ORDER_CONFIRMATION);
        break;
    }
  }, []);

  const Footer = () => (
    <>
      <StyledButton
        label={t('COMMON:buttons.confirm') || ''}
        variant="primary"
        onClick={onSubmitPageHandler}
        disabled={
          isLoading || updateProfileIsLoading || !!Object.keys(errors)?.length
        }
        adaptiveWidth={!isDesktop}
        {...addTestAttribute('orderDetails.button.confirm')}
      />
    </>
  );

  if (error) {
    const e = error?.response?.status;

    if (e !== 401) {
      return <GlobalErrorInfo retryHandler={refetch} />;
    }
  }

  if (updateProfileError) {
    const response = updateProfileError?.response;

    if (
      response?.status !== 401 &&
      response?.data?.code !== 'NOT_UNIQUE_EMAIL'
    ) {
      return <GlobalErrorInfo retryHandler={updateProfileRefetch} />;
    }
  }

  if (profileError) {
    const profileErrorStatus = profileError?.response?.status;

    if (profileErrorStatus !== 401 && profileErrorStatus !== 403) {
      return <GlobalErrorInfo retryHandler={profileRefetch} />;
    }
  }

  if (updateProfileIsLoading || isLoading || profileIsLoading) {
    return <Skeleton />;
  }

  return (
    <BaseLayout footer={<Footer />}>
      <PageBackButton
        variant="text"
        icon={<ArrowLeftIcon2 />}
        onClick={handleClickBack}
      >
        {t('COMMON:buttons.back')}
      </PageBackButton>
      <Container>
        {/* <OrderHeader bgUrl={bgUrl}> // TODO: добавить цветные шапки когда будем делать темизацию */}
        <OrderTitle>{getPageTitle(profile?.profile.firstName)}</OrderTitle>
        <OrderSubTitle>
          {orderSubTitle}
          {pageSubTitle}
        </OrderSubTitle>
        {/* </OrderHeader> */}
      </Container>
      {orderPageTitle && (
        <Container>
          <OrderPageTitleWrapper {...addTestAttribute('orderDetails.title')}>
            {orderPageTitle}
          </OrderPageTitleWrapper>
        </Container>
      )}
      <OrderContentWrapper>
        {(order as any[])
          ?.filter(
            (
              item: OrderItemType | OrderItemsBlockType | OrderItemAccordionType
            ) => item.value
          )
          ?.map((orderItem: OrderItemsBlockType | OrderItemType, index) =>
            orderItem.type === OrderItemValueType.BLOCK ? (
              <OrderBlockItem
                key={uuid()}
                {...(orderItem as unknown as OrderItemsBlockType)}
                showSeparator={isTelevetPlusRoute}
                testId={`orderDetails.${index}`}
              />
            ) : orderItem.type === OrderItemValueType.ACCORDION ? (
              <OrderAccordionItem
                key={`${orderItem.type}_${orderItem.title}`}
                {...(orderItem as unknown as OrderItemAccordionType)}
                testId={`orderDetails.${index}`}
              />
            ) : (
              <OrderItem
                key={uuid()}
                {...(orderItem as unknown as OrderItemType)}
                testId={`orderDetails.${index}`}
              />
            )
          )}
        <EmailWrapper>
          <FormSubTitleWithSubText
            title={emailLabel}
            subTitle={
              agentLogin ? t('COMMON:labels.sendDocumentsToClientMail') : ''
            }
          />
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value }, fieldState }) => (
              <HelperText
                status={fieldState.error ? 'error' : 'default'}
                message={errors.email?.message && t(errors.email.message)}
                testId="orderDetails.email.error"
              >
                <Input
                  name="email"
                  value={value || ''}
                  label={t('COMMON:labels.email') || ''}
                  onChange={(value) => {
                    onChange(value.toLocaleLowerCase());
                  }}
                  onBlur={onBlur}
                  error={!!errors.email}
                  {...addTestAttribute('orderDetails.email')}
                />
              </HelperText>
            )}
          />
        </EmailWrapper>
      </OrderContentWrapper>
    </BaseLayout>
  );
};
