/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Datepicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Info } from '@pulse-web-ui/icons';
import { Input } from '@pulse-web-ui/input';
import { useTheme } from '@pulse-web-ui/theme';

import {
  AdaptiveHeadersWrapper,
  Container,
  FormSubTitle,
  InfoWrapper,
  PaymentPeriodCardDescription,
  PaymentPeriodCardTitle,
  RadioDescriptionRender,
  RadioLabelRender,
  Skeleton,
  SubHeaderAdaptiveWarning,
  SubHeadersWrapper,
  WarnigText,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  ApiEndpoints,
  DEFAULT_FIAS_ID,
  FeatureFlags,
  HouseSteps,
  analyticEvents,
  durationDescription,
} from '@src/constants';
import { FIRST_STEP_FOR_SETTINGS } from '@src/constants/preset';
import { FormIFLGetPricesFull } from '@src/constants/request-data';
import {
  GlobalErrorInfo,
  PaymentPeriodHint,
  PaymentPeriodList,
  PaymentPeriodsTitle,
  PromoCodeDiscount,
  SettingsWrapper,
} from '@src/features';
import {
  useBlockNextStep,
  useHandlePressKey,
  useNextStep,
  useRequest,
  useValidateProfileAuth,
} from '@src/hooks';
import {
  AuthActionTypes,
  AvailableSubscriptionActionTypes,
  CheckUserDataActionTypes,
  IFLFlatActionTypes,
  OrderActionTypes,
  PresetSettingActionTypes,
  Store,
  UserActionTypes,
} from '@src/store';
import { IFLHouseActionTypes } from '@src/store/ifl-house';
import { WizardActionTypes } from '@src/store/wizard';
import { FormPromoProps, KeyCode, SelectedDuration } from '@src/types';
import {
  addTestAttribute,
  checkDateBefore,
  convertDateByTimeZone,
  getPaymentPeriodCardSubtitle,
  parseProductCode,
  resetPromo,
  sendHouseProgress,
  setPaymentPageTexts,
} from '@src/utils';

import { formInsurancePeriodSchema } from '../../schemas';
import {
  ContentWrapper,
  PromoSubmitButton,
  PromoTextInput,
  PromoWrapper,
} from './form-insurance-period.styles';
import {
  useHouseOrderArray,
  useIflHouseDraft,
  useIflOrderHouseData,
  useRequestData,
} from './hooks';

export const FormInsurancePeriod: FC = () => {
  const queryClient = useQueryClient();
  const theme: any = useTheme();
  const { t } = useTranslation();
  const [isInputActive, setIsInputActive] = useState(false);

  const {
    state: {
      stateFormIFLHouse: {
        promoCode,
        promoCodeApplyed,
        risks,
        insuranceSubproducts,
        selectedDuration,
        selectedRegion,
        selectedDate,
        selectedIProduct,
        getPricesFull,
        dadaValue,
        presetData,
        selectedBuildingMaterialCode,
      },
      stateAuth: { authTokens },
      stateUser: {
        profile,
        promoCodeFailedMessage,
        preset,
        agentLogin,
        clientCategoryFromUrl,
      },
      stateAuthFlow: { authStep, sepScrAuth },
      stateWizard: { currentStep, globalError },
    },
    dispatch,
  } = useContext(Store);

  const setHouseOrderArray = useHouseOrderArray();
  const { productCode, productVersion } = useMemo(
    () => parseProductCode(selectedIProduct?.code),
    [selectedIProduct?.code]
  );

  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [dateEqual, setDateEqual] = useState<boolean>(false);
  const [submitPromoDisabled, setSubmitPromoDisabled] = useState(false);
  const [autoInitPromo, setAutoInitPromo] = useState(false);
  const [localPromo, setLocalPromo] = useState<string | undefined>();

  const { profileRefetch, profileIsLoading, profileError } =
    useValidateProfileAuth();

  useEffect(() => {
    if (!preset) {
      sendAnalyticEvent(analyticEvents.iflStep5Period);
      sendAnalyticEvent(analyticEvents.iflHouseStep5Period);
    }
  }, [preset]);

  const navigate = useNavigate();

  const { subobjectsError, isPricesLoading, pricesError, pricesRes } =
    useRequestData(autoInitPromo, localPromo);
  const orderData = useIflOrderHouseData();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    watch,
    getValues,
  } = useForm<FormPromoProps>({
    resolver: yupResolver(formInsurancePeriodSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      promoCode:
        promoCodeApplyed && getPricesFull?.isSuccessfulPromo
          ? promoCodeApplyed
          : promoCode,
    },
  });

  useEffect(() => {
    if (selectedDate !== undefined) {
      localStorage.setItem('selectedDate', JSON.stringify(selectedDate));
    }
  }, [selectedDate]);

  useEffect(() => {
    const subscription = watch((value) => {
      dispatch({
        type: IFLHouseActionTypes.SetPromoCode,
        payload: value.promoCode,
      });
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  useEffect(() => {
    if (promoCodeApplyed && getPricesFull?.isSuccessfulPromo) {
      setLocalPromo(promoCodeApplyed);
      setAutoInitPromo(true);
    }

    // Проверка добавлена для нового клиента, актуально для ИФЛ Дом.
    // Из-за особенностей реализации эндпойнта get-subobject на бэкенде не проходит корректная
    // генерация подобъектов для пользователя, который в конце флоу меняет свой тип на ВИП.

    if (
      (!profile?.profile.firstName ||
        profile?.profile.clientCategory !== 'VIP') &&
      clientCategoryFromUrl === 'VIP'
    ) {
      profileRefetch().then((data) => {
        if (data.error?.response?.status === 403) {
          navigate('/personal-info', {
            state: { nextRoute: '/' },
          });
        }
      });
    }
  }, []);

  const validatePage = useCallback(() => {
    if (!insuranceSubproducts) {
      return false;
    }

    dispatch({
      type: CheckUserDataActionTypes.SetProductCode,
      payload: selectedIProduct?.code,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetRisks,
      payload: risks?.filter((riskItem) => riskItem.active),
    });
    dispatch({
      type: CheckUserDataActionTypes.SetSubobjects,
      payload: insuranceSubproducts?.subObjects,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetPromoCode,
      payload: autoInitPromo ? localPromo : undefined,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetAddress,
      payload: dadaValue?.value || '',
    });
    dispatch({
      type: CheckUserDataActionTypes.SetRegionCode,
      payload: selectedRegion?.region,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetAddressCode,
      payload: dadaValue?.data?.fias_id || DEFAULT_FIAS_ID,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetPrices,
      payload: getPricesFull?.prices,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetSelectedDuration,
      payload: selectedDuration || undefined,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetBuildingMaterial,
      payload: selectedBuildingMaterialCode,
    });
    dispatch({
      type: AvailableSubscriptionActionTypes.SetOrderData,
      payload: orderData,
    });

    // check for clientCategory
    if (
      !profile?.profile.lastName ||
      (profile?.profile.clientCategory !== 'VIP' &&
        clientCategoryFromUrl === 'VIP')
    ) {
      profileRefetch().then((data) => {
        if (
          data.error?.response?.status !== 403 &&
          data.error?.response?.data?.code !== 'INVALID_ROLE'
        ) {
          navigate('/check-user-data');
        } else {
          navigate('/personal-info', {
            state: { nextRoute: '/check-user-data' },
          });
        }
      });
    } else {
      navigate('/check-user-data');
    }

    return false;
  }, [
    selectedIProduct?.code,
    risks,
    insuranceSubproducts,
    autoInitPromo,
    localPromo,
    dadaValue?.value,
    dadaValue?.data?.fias_id,
    selectedRegion?.region,
    getPricesFull?.prices,
    selectedDuration,
    selectedBuildingMaterialCode,
  ]);

  useNextStep(validatePage);
  useIflHouseDraft();

  const setSelectedDate = (newDate: Date) => {
    setStepUpdated();
    dispatch({
      type: IFLHouseActionTypes.SetSelectedDate,
      payload: convertDateByTimeZone(newDate),
    });
    if (!preset) {
      sendAnalyticEvent(analyticEvents.dateStartRealty);
    }
  };

  const onRadioSelector = (val: SelectedDuration) => {
    setStepUpdated();

    dispatch({
      type: IFLHouseActionTypes.SetSelectedDuration,
      payload: val,
    });

    if (!preset) {
      sendAnalyticEvent(analyticEvents.periodSelectRealty, {
        period: durationDescription[val],
      });
    }
  };

  const {
    isLoading: isInsuranceLimitStartLoading,
    error: insuranceLimitStartError,
    res: insuranceLimitStartRes,
    refetch: refetchInsuranceLimitStart,
  } = useRequest(
    'formIFLGetInsuranceLimitStart',
    'get',
    `${ApiEndpoints.INSURANCE_LIMIT_START}/${productCode}/${productVersion}`,
    [productCode, productVersion]
  );

  useBlockNextStep(
    isPricesLoading || isInsuranceLimitStartLoading || profileIsLoading,
    pricesError || insuranceLimitStartError || profileError
  );

  useEffect(() => {
    if (
      (selectedDate &&
        insuranceLimitStartRes &&
        (checkDateBefore(
          new Date(selectedDate),
          new Date(insuranceLimitStartRes?.startDate)
        ) ||
          checkDateBefore(
            new Date(insuranceLimitStartRes?.endDate),
            new Date(selectedDate)
          ))) ||
      dateEqual
    ) {
      setSelectedDate(new Date(insuranceLimitStartRes?.startDate));
    }
  }, [insuranceLimitStartRes, dateEqual]);

  useEffect(() => {
    if (isPricesLoading || isInsuranceLimitStartLoading) {
      setIsInputActive(false);
    }
  }, [isPricesLoading, isInsuranceLimitStartLoading]);

  const handleKeyPressEnter = () => {
    if (isInputActive) {
      submitPromoCode();
    } else if (currentStep !== authStep || sepScrAuth) {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [isInputActive]);

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrderPageTitle,
      payload: t('COMMON:labels.pulseHouse') || '',
    });
  }, []);

  const { promoCode: promoCodeValue } = getValues();
  resetPromo(promoCodeValue, promoCodeApplyed, null, watch('promoCode'));

  useEffect(() => {
    setPaymentPageTexts(t('COMMON:hints.somethingHappensHome'), selectedDate);

    setHouseOrderArray(pricesRes);
  }, [pricesRes, selectedDate, setHouseOrderArray]);

  useEffect(() => {
    if (!isPricesLoading && pricesRes) {
      dispatch({
        type: IFLHouseActionTypes.SetGetPricesFull,
        payload: pricesRes,
      });
      if (!selectedDuration && pricesRes.defaultContractDuration) {
        dispatch({
          type: IFLHouseActionTypes.SetSelectedDuration,
          payload: pricesRes.defaultContractDuration,
        });
      } else if (
        !selectedDuration ||
        pricesRes?.prices.every(({ duration }) => duration !== selectedDuration)
      ) {
        dispatch({
          type: IFLHouseActionTypes.SetSelectedDuration,
          payload: pricesRes?.prices[0]?.duration,
        });
      }

      const isPromoCodeAppliedSuccessfully =
        pricesRes?.isSuccessfulPromo && promoCode !== '';

      let isButtonHasToBeDisabled = true;

      if (errors.promoCode) {
        isButtonHasToBeDisabled = false;
      }
      if (isPromoCodeAppliedSuccessfully) {
        isButtonHasToBeDisabled = true;
      }

      if (pricesRes?.isSuccessfulPromo === false && promoCode !== '') {
        setError('promoCode', {
          type: 'string',
          message: 'COMMON:errors.promoCodeNotValid',
        });
        isButtonHasToBeDisabled = true;
      }

      if (
        pricesRes?.isSuccessfulPromo === undefined &&
        promoCode !== '' &&
        !autoInitPromo
      ) {
        isButtonHasToBeDisabled = false;
      }

      if (
        pricesRes?.isSuccessfulPromo &&
        promoCode !== '' &&
        !promoCodeApplyed
      ) {
        dispatch({
          type: IFLHouseActionTypes.SetPromoCodeApplyed,
          payload: promoCode,
        });
      }

      setSubmitPromoDisabled(isButtonHasToBeDisabled);
    }
  }, [isPricesLoading, pricesRes]);

  useEffect(() => {
    if (!isInsuranceLimitStartLoading && insuranceLimitStartRes) {
      setMinDate(new Date(insuranceLimitStartRes?.startDate));
      setMaxDate(new Date(insuranceLimitStartRes?.endDate));
      if (
        JSON.stringify(insuranceLimitStartRes?.startDate) ===
        JSON.stringify(insuranceLimitStartRes?.endDate)
      ) {
        setDateEqual(true);
      } else setDateEqual(false);
      setSelectedDate(
        selectedDate || new Date(insuranceLimitStartRes?.startDate)
      );
    }
  }, [isInsuranceLimitStartLoading, insuranceLimitStartRes]);

  useEffect(() => {
    if (authTokens?.authorization?.accessToken) {
      reset();
    }
  }, [authTokens?.authorization?.accessToken]);

  useEffect(() => {
    if (pricesRes?.isSuccessfulPromo === false && promoCode !== '') {
      setError('promoCode', {
        type: 'string',
        message: 'COMMON:errors.promoCodeNotValid',
      });
      dispatch({
        type: UserActionTypes.SetPromoCodeFailedMessage,
        payload: t('COMMON:errors.promoCodeNotValid') || '',
      });
      dispatch({
        type: IFLHouseActionTypes.SetPromoCodeApplyed,
        payload: undefined,
      });
    }
  }, [isPricesLoading, pricesRes, promoCode]);

  const checkSubmitState = (val: string) => {
    setSubmitPromoDisabled(val === promoCode);
  };

  const submitPromoCode = handleSubmit((data) => {
    dispatch({
      type: IFLHouseActionTypes.SetPromoCode,
      payload: data.promoCode,
    });

    dispatch({
      type: UserActionTypes.SetPromoCodeFailedMessage,
      payload: undefined,
    });

    dispatch({
      type: IFLHouseActionTypes.SetPromoCodeApplyed,
      payload: undefined,
    });

    setAutoInitPromo(true);
    setLocalPromo(promoCode);
  });

  const getLabel = () => {
    const { promoCode: statePromoCode } = getValues();
    const isPromocodeAppliedSuccessfully = pricesRes?.isSuccessfulPromo;

    return isPromocodeAppliedSuccessfully && promoCode === statePromoCode
      ? t('COMMON:success.applied')
      : t('COMMON:success.apply');
  };

  const handleClickSettingPreset = () => {
    sendAnalyticEvent(analyticEvents.iflHousePresetToConfig);
    navigate('/preset-settings');
    dispatch({
      type: PresetSettingActionTypes.SetIsPresetSettingDisplayed,
      payload: true,
    });
    dispatch({
      type: WizardActionTypes.SetCurrentStep,
      payload: FIRST_STEP_FOR_SETTINGS,
    });

    queryClient.resetQueries(FormIFLGetPricesFull);
  };

  useEffect(() => {
    if (!!presetData) {
      sendAnalyticEvent(analyticEvents.iflHousePresetStep2Period);
    }
  }, []);

  useEffect(() => {
    if (
      !isPricesLoading &&
      !isInsuranceLimitStartLoading &&
      !!insuranceSubproducts &&
      !!getPricesFull &&
      !(
        profile?.profile?.clientCategory !== 'VIP' &&
        clientCategoryFromUrl === 'VIP'
      )
    ) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });
    }
  }, [
    isPricesLoading,
    isInsuranceLimitStartLoading,
    insuranceSubproducts,
    getPricesFull,
    globalError,
  ]);

  useEffect(() => {
    if (!!presetData) {
      dispatch({
        type: WizardActionTypes.SetIsPageLoading,
        payload:
          isPricesLoading ||
          isInsuranceLimitStartLoading ||
          profileIsLoading ||
          !getPricesFull ||
          !insuranceSubproducts,
      });
    } else {
      dispatch({
        type: WizardActionTypes.SetIsPageLoading,
        payload:
          isPricesLoading || isInsuranceLimitStartLoading || profileIsLoading,
      });
    }
  }, [
    presetData,
    getPricesFull,
    insuranceSubproducts,
    isPricesLoading,
    isInsuranceLimitStartLoading,
    profileIsLoading,
  ]);

  useEffect(() => {
    sendHouseProgress(HouseSteps.PAYMENT_PERIODS);
  }, []);

  if (subobjectsError?.response?.data?.code === 'USER_DATA_ERROR') {
    navigate('/score-error');
  }

  if (pricesError?.response?.data?.code === 'OBJECT_DATA_ERROR') {
    dispatch({
      type: IFLFlatActionTypes.SetIsObjectDataError,
      payload: true,
    });

    navigate('/score-error');
  }

  if (!!presetData) {
    if (
      isPricesLoading ||
      isInsuranceLimitStartLoading ||
      !getPricesFull ||
      !insuranceSubproducts
    ) {
      return <Skeleton />;
    }
  } else {
    if (isPricesLoading || isInsuranceLimitStartLoading) return <Skeleton />;
  }

  if (profileIsLoading) return <Skeleton />;

  if (pricesError || insuranceLimitStartError) {
    const error = pricesError || insuranceLimitStartError;
    const status = error?.response?.status;
    if (status === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: t('COMMON:errors.authorizationError'),
          subtitle: t('COMMON:errors.retryRegistration') || '',
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/authorize-fail');
    }
    return <GlobalErrorInfo retryHandler={refetchInsuranceLimitStart} />;
  }

  if (
    profile?.profile?.clientCategory !== 'VIP' &&
    clientCategoryFromUrl === 'VIP'
  ) {
    return <GlobalErrorInfo />;
  }

  // if (profileError) {
  //   return (
  //     <GlobalErrorInfo
  //       pending={profileIsLoading}
  //       retrayHandler={profileRefetch}
  //     />
  //   );
  // }

  return (
    <Container>
      <AdaptiveHeadersWrapper>
        <SettingsWrapper>
          <PaymentPeriodsTitle
            featureFlag={FeatureFlags.HouseSubscriptionType}
            marginBottom={0}
          />
          {!!presetData && (
            <Button variant="setting" onClick={handleClickSettingPreset}>
              {t('COMMON:buttons.settingSubscription')}
            </Button>
          )}
        </SettingsWrapper>
        {agentLogin && (
          <SubHeadersWrapper>
            <SubHeaderAdaptiveWarning>
              {t('COMMON:hints.priceMayChange')}
            </SubHeaderAdaptiveWarning>
          </SubHeadersWrapper>
        )}
      </AdaptiveHeadersWrapper>

      <PaymentPeriodList
        onChange={onRadioSelector}
        selectedDuration={selectedDuration}
        prices={getPricesFull?.prices || []}
        getLabel={(price, isFeatureSubscriptionType) =>
          isFeatureSubscriptionType ? (
            <PaymentPeriodCardTitle
              premiumAndDelta={price.premiumAndDelta}
              premiumAndDeltaPromo={price.premiumAndDeltaPromo}
            />
          ) : (
            RadioLabelRender(price)
          )
        }
        getDescription={(price, isFeatureSubscriptionType) =>
          isFeatureSubscriptionType ? (
            <PaymentPeriodCardDescription duration={price.duration} />
          ) : (
            RadioDescriptionRender(price)
          )
        }
        getSubtitle={(price) => getPaymentPeriodCardSubtitle(price.duration)}
        featureFlag={FeatureFlags.HouseSubscriptionType}
      />

      <ContentWrapper>
        <PromoWrapper>
          <PromoTextInput>
            <Controller
              control={control}
              name="promoCode"
              render={({ field: { onChange, value }, fieldState }) => (
                <HelperText
                  status={
                    fieldState.error || promoCodeFailedMessage
                      ? 'error'
                      : 'success'
                  }
                  message={
                    (errors.promoCode?.message &&
                      t(errors.promoCode.message)) ||
                    promoCodeFailedMessage ||
                    (pricesRes?.isSuccessfulPromo &&
                    promoCodeApplyed !== '' &&
                    getPricesFull?.prices ? (
                      <PromoCodeDiscount
                        prices={getPricesFull?.prices}
                        featureFlag={FeatureFlags.HouseDiscount}
                      />
                    ) : (
                      ''
                    ))
                  }
                  testId="house-form-promocode-error"
                >
                  <Input
                    label={t('COMMON:labels.promoCode') || ''}
                    value={value}
                    onFocus={() => setIsInputActive(true)}
                    onBlur={() => setIsInputActive(false)}
                    onChange={(val) => {
                      checkSubmitState(val);
                      return onChange(val);
                    }}
                    error={!!errors.promoCode || !!promoCodeFailedMessage}
                    disabled={isPricesLoading || isInsuranceLimitStartLoading}
                    {...addTestAttribute('house-form-promocode')}
                  />
                </HelperText>
              )}
            />
          </PromoTextInput>
          <PromoSubmitButton>
            <Button
              label={getLabel()}
              onClick={submitPromoCode}
              disabled={submitPromoDisabled}
              variant="secondary-2"
              adaptiveWidth
              {...addTestAttribute('house-form-promocode-button-apply')}
            />
          </PromoSubmitButton>
        </PromoWrapper>
      </ContentWrapper>
      <FormSubTitle>{t('COMMON:labels.subscriptionActive')}</FormSubTitle>
      <HelperText
        message={
          !isPricesLoading &&
          !isInsuranceLimitStartLoading &&
          !dateEqual && (
            <PaymentPeriodHint
              featureFlag={FeatureFlags.HouseSubscriptionType}
              minDate={minDate}
              maxDate={maxDate}
            />
          )
        }
        status="default"
        messagePosition="down"
        testId="house-form-subscription-start-error"
      >
        {isPricesLoading || isInsuranceLimitStartLoading ? (
          <Skeleton />
        ) : dateEqual ? (
          <InfoWrapper>
            <Info width={24} color={theme.colors.icon.primary} />
            <WarnigText
              {...addTestAttribute('house-form-subscription-start-message')}
            >
              {`${t(
                'COMMON:labels.subscriptionWillStart'
              )} ${convertDateByTimeZone(minDate)?.toLocaleDateString('ru-RU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}`}
            </WarnigText>
          </InfoWrapper>
        ) : (
          <Datepicker
            onChange={setSelectedDate}
            selected={selectedDate}
            minDate={convertDateByTimeZone(minDate)}
            maxDate={convertDateByTimeZone(maxDate)}
            {...addTestAttribute('house-form-subscription-start')}
          />
        )}
      </HelperText>
    </Container>
  );
};
