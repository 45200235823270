/* eslint-disable indent */
import { useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { isBrowser } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { DisplayQRButton } from '@pulse-web-ui/display-qr-button';
import { RGSFilter, RGSLicence, RGSMessage } from '@pulse-web-ui/icons';

import {
  PageSubTitleRegular18,
  PageTitle,
  StyledFormSub,
} from '@src/common-components/headers';
import {
  FormSubLabel,
  GridColumnContainer,
  HeaderSubMedium18,
  UniversalColumn,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic';
import {
  Product,
  analyticEvents,
  nonSubscriptionProducts,
} from '@src/constants';
import { RGSSupportBlock } from '@src/features';
import { getDeeplink, useScrollToIframeOnMount } from '@src/utils';

import { BaseLayout } from '../../layouts/base-layout';
import {
  StyledPayCard,
  StyledPayCardImageWrapper,
  StyledPayCardText,
  StyledPayCards,
} from './best-to-pay-page.styles';

export const BestToPayInProgressPage: FC = () => {
  const { t } = useTranslation();
  const storedStartDate = localStorage.getItem('startDate');
  const [searchParams] = useSearchParams();
  const selectedProduct = localStorage.getItem('selectedProduct') as Product;
  const preset = localStorage.getItem('preset');
  const currentSelectedProduct = localStorage.getItem('selectedProduct');

  const pageSubTitle = useMemo(() => {
    switch (selectedProduct) {
      case Product.PETS:
        return t('BEST_TO_PAY:hints.comingSoonToMobileApp');
      default:
        return t('BEST_TO_PAY:hints.usuallyItHappensInstantly');
    }
  }, [selectedProduct, t]);

  const appButtonLabel = useMemo(() => {
    switch (selectedProduct) {
      case Product.PETS:
        return t('COMMON:buttons.installApp');
      default:
        return t('COMMON:buttons.downloadApp');
    }
  }, [selectedProduct, t]);

  const filterCardDescription = nonSubscriptionProducts.includes(
    currentSelectedProduct || ''
  )
    ? t('BEST_TO_PAY:hints.cancelInsurance')
    : t('BEST_TO_PAY:hints.controlSubscription');

  useEffect(() => {
    if (selectedProduct === Product.HOUSE && preset) {
      sendAnalyticEvent(analyticEvents.iflHousePresetPaymentSuccess);
    } else if (selectedProduct === Product.APARTMENT && preset) {
      sendAnalyticEvent(analyticEvents.iflFlatPresetPaymentSuccess);
    }
  }, []);

  useScrollToIframeOnMount();

  useEffect(() => {
    if (searchParams.get('reference')) {
      localStorage.setItem(
        'referenceSuccess',
        String(searchParams.get('reference'))
      );
      localStorage.setItem(
        'operationId',
        String(searchParams.get('operation'))
      );
    }
  }, []);

  return (
    <BaseLayout verticalAlignment={true}>
      <GridColumnContainer gridStep={24}>
        <UniversalColumn align="left" gap={0}>
          <PageTitle>{t('BEST_TO_PAY:headers.processingPayment')}</PageTitle>
          {storedStartDate ? (
            <PageSubTitleRegular18>
              {t('COMMON:labels.willStartToAct')}{' '}
              {new Date(storedStartDate).toLocaleDateString('ru', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </PageSubTitleRegular18>
          ) : null}
          <FormSubLabel>{pageSubTitle}</FormSubLabel>
        </UniversalColumn>
        <StyledFormSub margin="0 0 32px">
          {isBrowser ? (
            <DisplayQRButton buttonLabel={appButtonLabel} />
          ) : (
            <Button
              variant="primary"
              label={appButtonLabel}
              onClick={getDeeplink}
            />
          )}
        </StyledFormSub>
      </GridColumnContainer>
      <UniversalColumn align="left">
        <HeaderSubMedium18>
          {t('BEST_TO_PAY:headers.InApplicationYouCan')}
        </HeaderSubMedium18>
      </UniversalColumn>
      <StyledPayCards>
        <StyledPayCard>
          <StyledPayCardImageWrapper>
            <RGSFilter width={64} />
          </StyledPayCardImageWrapper>
          <StyledPayCardText>{filterCardDescription}</StyledPayCardText>
        </StyledPayCard>

        <StyledPayCard>
          <StyledPayCardImageWrapper>
            <RGSMessage width={64} />
          </StyledPayCardImageWrapper>
          <StyledPayCardText>
            {t('BEST_TO_PAY:hints.getAdvice')}
          </StyledPayCardText>
        </StyledPayCard>
        <StyledPayCard>
          <StyledPayCardImageWrapper>
            <RGSLicence width={64} />
          </StyledPayCardImageWrapper>
          <StyledPayCardText>
            {t('BEST_TO_PAY:hints.getUsefulMaterialsAndDocuments')}
          </StyledPayCardText>
        </StyledPayCard>
      </StyledPayCards>
      <RGSSupportBlock />
    </BaseLayout>
  );
};
