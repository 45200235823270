import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  ErrorOption,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Container, FormLabel, Skeleton } from '@src/components';
import { insurancePersonDefaultData } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { InsurancePersonForm } from '@src/features/insurance-person-form';
import { useRequest } from '@src/hooks';
import { orderRequestPersonsData } from '@src/pages/ns-form/utils';
import { updateOrderProfileData } from '@src/pages/ns-form/utils/update-order-profile-data';
import { personsFormSchema } from '@src/schemas';
import {
  AuthActionTypes,
  OrderActionTypes,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import { InsurancePersonsForm, InsurePerson } from '@src/types';
import { getFormattedDate, setPaymentPageTexts } from '@src/utils';

import {
  useNsDetailsInsured,
  useNsDraft,
  useNsOrderData,
  useOrderDataFormatting,
} from './hooks';

export const FormNsDetailsInsured = () => {
  const { t } = useTranslation();
  const [changedProfileData, setChangedProfileData] = useState<null | Record<
    string,
    string
  >>(null);
  const {
    state: {
      stateFormNS: { insurePersons, selectedDate },
      stateWizard: { wantNextStep },
      stateUser: { profile: stateProfile, profile },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const [submitPersonsData, setSubmitPersonsData] = useState<
    undefined | InsurePerson[]
  >();
  const [indexMyPersonForm, setIndexMyPersonForm] = useState(-1);
  const { orderData } = useNsOrderData();
  const navigate = useNavigate();
  const { storeWantNextStep, storeInsurePersons, profileDataForForm } =
    useNsDetailsInsured();
  const { orderArray } = useOrderDataFormatting();

  const defaultValues = useMemo(
    () => ({
      persons: insurePersons.map((person) => ({
        id: uuidv4(),
        ...person,
      })),
    }),
    [insurePersons]
  );

  const methods = useForm<InsurancePersonsForm>({
    mode: 'all',
    resolver: yupResolver(personsFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    watch,
    setError,
    formState: { errors, isSubmitted },
  } = methods;

  const [_, setPersonsErrors] = useState(errors.persons);

  const { fields } = useFieldArray({
    control,
    name: 'persons',
    keyName: 'key',
  });

  useEffect(() => {
    if (isSubmitted) {
      setPersonsErrors(errors.persons);
    }
  }, [isSubmitted, errors.persons]);

  const handleSelectionMe = (personsIndex: number) => {
    const formData = getValues();
    const newData = formData.persons.map((item, idx) => {
      if (idx === personsIndex) {
        if (item.isMe) {
          return {
            ...item,
            ...profileDataForForm,
            isMe: true,
            isDisabledForm: stateProfile?.hasSubscriptions,
          };
        }

        return {
          ...insurancePersonDefaultData,
          ageMin: item.ageMin,
          id: item.id,
        };
      }

      if (item.isMe) {
        return {
          ...insurancePersonDefaultData,
          ageMin: item.ageMin,
          id: item.id,
        };
      }

      return item;
    });

    reset({
      persons: newData,
    });

    setPersonsErrors((prevState) => {
      const newErrors = prevState?.map((err, index) => {
        if (index === personsIndex) {
          return {};
        }
        return err;
      });

      setError('persons', newErrors as ErrorOption);
      return newErrors;
    });

    setIndexMyPersonForm((prevIndex) =>
      prevIndex === personsIndex ? -1 : personsIndex
    );
  };

  const {
    error: updateProfileError,
    isLoading: updateProfileIsLoading,
    res: updateProfileRes,
    refetch: updateProfileRefetch,
  } = useRequest(
    'updateProfileNSRequest',
    'post',
    '/v1/user/update-profile',
    {
      clientChange: {
        ...changedProfileData,
        email: stateProfile?.profile?.email,
        phone: stateProfile?.profile?.phone,
      },
    },
    [changedProfileData, profile],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  useEffect(() => {
    if (updateProfileIsLoading) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: true,
      });
    }
  }, [updateProfileIsLoading]);

  useEffect(() => {
    const subscription = watch((value) =>
      storeInsurePersons(value.persons as InsurePerson[])
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  useNsDraft();
  useEffect(() => {
    if (wantNextStep) {
      storeWantNextStep(false);

      handleSubmit((data) => {
        let isEqual = true;
        const changedUserData = data.persons.filter((item) => item.isMe);
        if (changedUserData?.length) {
          for (const key in profileDataForForm) {
            if (
              profileDataForForm[key as keyof typeof profileDataForForm] !==
              changedUserData[0][key as keyof typeof profileDataForForm]
            ) {
              isEqual = false;
              break;
            }
          }
        }

        setPaymentPageTexts(t('COMMON:hints.ifSomethingHappens'), selectedDate);

        if (isEqual) {
          if (stateProfile?.profile) {
            dispatch({
              type: OrderActionTypes.SetOrder,
              payload: updateOrderProfileData(orderArray, stateProfile.profile),
            });
          }

          orderData.accidents = {
            persons: orderRequestPersonsData(data.persons),
          };

          dispatch({
            type: OrderActionTypes.SetOrderRequestData,
            payload: orderData,
          });
          navigate('/order-detail');
        } else if (changedUserData?.length) {
          const { firstName, lastName, middleName, birthDate } =
            changedUserData[0];

          const updateUserProfileData: Record<string, string> = {};
          if (!stateProfile?.lockedFields?.firstName && firstName) {
            updateUserProfileData.firstName = firstName.trim();
          }
          if (!stateProfile?.lockedFields?.middleName) {
            updateUserProfileData.middleName = middleName?.trim() || '';
          }
          if (!stateProfile?.lockedFields?.lastName && lastName) {
            updateUserProfileData.lastName = lastName.trim();
          }
          if (!stateProfile?.lockedFields?.birthDate && birthDate) {
            updateUserProfileData.birthDate = getFormattedDate(
              new Date(birthDate)
            );
          }

          setChangedProfileData(updateUserProfileData);
          setSubmitPersonsData(data.persons);
        }
      })();
    }
  }, [wantNextStep]);

  useEffect(() => {
    if (
      !updateProfileIsLoading &&
      updateProfileRes &&
      submitPersonsData &&
      changedProfileData
    ) {
      const newData = [...submitPersonsData];

      const myIndex = newData.findIndex((item) => item.isMe);
      newData[myIndex].primaryRecordId =
        updateProfileRes.profile.primaryRecordId;

      orderData.accidents = {
        persons: orderRequestPersonsData(newData),
      };

      dispatch({
        type: OrderActionTypes.SetOrderRequestData,
        payload: orderData,
      });

      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      navigate('/order-detail');
    }
  }, [
    updateProfileIsLoading,
    updateProfileRes,
    submitPersonsData,
    changedProfileData,
  ]);

  useEffect(() => {
    if (changedProfileData) {
      updateProfileRefetch();
    }
  }, [changedProfileData]);

  useEffect(() => {
    if (!updateProfileIsLoading && updateProfileRes) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      dispatch({
        type: UserActionTypes.SetProfile,
        payload: updateProfileRes,
      });

      dispatch({
        type: OrderActionTypes.SetOrder,
        payload: updateOrderProfileData(orderArray, updateProfileRes.profile),
      });
    }
  }, [updateProfileRes]);

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrderPageTitle,
      payload: t('NS_FORM:labels.movement') || '',
    });

    setIndexMyPersonForm(insurePersons.findIndex(({ isMe }) => isMe));
  }, []);

  if (updateProfileIsLoading) return <Skeleton />;

  if (updateProfileError) {
    const e = updateProfileError?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: t('COMMON:errors.somethingWentWrong'),
          subtitle: t('COMMON:errors.retryRegistration'),
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/authorize-fail');
    }

    return <GlobalErrorInfo retryHandler={updateProfileRefetch} />;
  }

  return (
    <FormProvider {...methods}>
      <Container>
        <FormLabel marginBottom={32}>
          {t('NS_FORM:headers.enterInsured')}
        </FormLabel>
        {fields.map((field, fieldIndex) => (
          <InsurancePersonForm
            key={field.id}
            showFormTitle
            pressEnterNextStep
            isLastPerson={fieldIndex + 1 === fields.length}
            personsIndex={fieldIndex}
            selectedIndex={indexMyPersonForm}
            ageMin={field.ageMin}
            effectiveSince={selectedDate || new Date()}
            handleSelectionMe={handleSelectionMe}
            lockedFields={stateProfile?.lockedFields}
          />
        ))}
      </Container>
    </FormProvider>
  );
};
