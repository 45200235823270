import { Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Product } from '@src/constants';
import { StepWizardForm } from '@src/features';
import { useComponentsConfig } from '@src/hooks';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';
import { ComponentsConfigType } from '@src/types';

import { useAnalyticsSport } from './hooks';

export const SportNSForm = () => {
  const {
    state: {
      stateWizard: { currentStep, isScrollToErrorElement },
      stateFormNSSport: { presetData },
    },
    dispatch,
  } = useContext(Store);

  const { components } = useComponentsConfig(
    Product.SPORT,
    !!presetData ? ComponentsConfigType.MAIN : ComponentsConfigType.DEFAULT
  );

  useAnalyticsSport();

  const navigate = useNavigate();

  const goBack = () => {
    if (currentStep === 1) {
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: undefined,
      });

      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetAuthenticationRefetchUid,
        payload: uuidv4(),
      });

      const productDirectLink = localStorage.getItem('productDirectLink');
      if (productDirectLink && productDirectLink !== null) {
        navigate('/');
      } else {
        navigate('/subscription-choice');
      }
    } else {
      navigate(-1);
    }
  };

  const maxStep = components?.length || 0;

  return (
    <StepWizardForm
      maxStep={maxStep}
      goBack={goBack}
      scrollToIframeOnChange={!isScrollToErrorElement}
    >
      {components?.map((Component, index) => {
        if (currentStep === index + 1) {
          return (
            <Fragment key={index}>
              <Component />
            </Fragment>
          );
        }
        return null;
      })}
    </StepWizardForm>
  );
};
