export enum WizardActionTypes {
  SetCurrentStep = 'WIZARD/SET_CURRENT_STEP',
  SetUpdateFormState = 'WIZARD/SET_UPDATE_FORM_STATE',
  SetFwNavDisabled = 'WIZARD/SET_FW_NAV_DISABLED',
  UpdateWantNextStep = 'WIZARD/UPDATE_WANT_NEXT_STEP',
  SetLastStep = 'WIZARD/SET_LAST_STEP',
  SetIsPageLoading = 'WIZARD/SET_IS_PAGE_LOADING',
  UpdateState = 'WIZARD/UPDATE_STATE',
  SetGlobalError = 'WIZARD/SET_GLOBAL_ERROR',
  SetIsScrollToErrorElement = 'WIZARD/SET_IS_SCROLL_TO_ERROR_ELEMENT',
}
