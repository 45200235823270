import { InsuranceSubProduct, OrderRequestData, Risk } from '@src/types';

export interface AvailableSubscriptionStoreState {
  risks?: Risk[];
  subobjects?: InsuranceSubProduct[];
  insuranceSum?: number;
  insuranceSumPerMonth?: number;
  insurancePromoSumPerMonth?: number;
  nextRoute?: string;
  prevRoute?: string;
  orderData?: OrderRequestData;
}

export const initAvailableSubscriptionStoreState: AvailableSubscriptionStoreState =
  {};
