import { styled, typography } from '@pulse-web-ui/theme';

export const SubTitle = styled.div`
  ${typography.regular16}
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 8px;
  line-height: 24px;
`;

export const Points = styled.ul`
  margin-bottom: 24px;
`;

export const MaterialFullDescriptionWrapper = styled.div`
  margin-bottom: 8px;
  line-height: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;
