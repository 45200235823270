import { HouseSteps, PaymentStatus } from '@src/constants';

export const sendHouseProgress = (
  stepHouse: number,
  status?: PaymentStatus
) => {
  if (stepHouse === HouseSteps.PAYMENT_RESULT) {
    window.parent.postMessage({ stepHouse, status }, '*');
  } else {
    window.parent.postMessage({ stepHouse }, '*');
  }
};
