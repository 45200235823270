import { InsuranceSubProduct, Price, Risk, SelectedDuration } from '@src/types';

export interface CheckUserDataStoreState {
  productCode?: string;
  risks?: Risk[];
  subobjects?: InsuranceSubProduct[];
  promoCode?: string;
  address?: string;
  regionCode?: string;
  addressCode?: string;
  buildingMaterial?: string;
  prices?: Price[];
  selectedDuration?: SelectedDuration;
}

export const initCheckUserDataStoreState: CheckUserDataStoreState = {};
