export const openPdf = (base64Pdf: string, name = 'document'): void => {
  const pdfBLob = new Blob([base64Pdf as any], {
    type: 'application/pdf',
  });

  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(pdfBLob, `${name}.pdf`);
  } else {
    const url = URL.createObjectURL(pdfBLob);

    const a = document.createElement('a');
    a.target = '_blank';
    a.href = url;
    a.click();
  }
};
